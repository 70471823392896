import React, { useState } from 'react';

const MailingListForm = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Subscribed:', email);
    setEmail('');
  };

  return (
    <div style={{ marginTop: '30px', width: '100%' }}>
      <h3 style={{ marginBottom: '10px', color: '#fff', fontFamily: 'Helvetica', fontWeight: '600', textTransform: 'uppercase' }}>
        Mailing List
      </h3>
      <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center' }}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          style={{
            padding: '8px',
            fontSize: '18px', // Same font size as body text
            backgroundColor: '#000',
            color: '#fff',
            border: '1px solid #fff',
            width: '250px',
            textAlign: 'center',
            fontFamily: 'Times New Roman',
          }}
        />
        <button
          type="submit"
          style={{
            padding: '10px 20px',
            fontSize: '18px', // Same font size as body text
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #fff',
            cursor: 'pointer',
            fontFamily: 'Helvetica',
            fontWeight: '600',
            // textTransform: 'uppercase',
          }}
        >
          Subscribe
        </button>
      </form>
    </div>
  );
};

const SocialLinks = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
      <a
        href="https://twitter.com/indefeasible_"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: '#fff',
          fontFamily: 'Helvetica',
          fontWeight: '600',
          transition: 'all 0.3s ease',
        }}
        onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
        onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
      >
        Twitter
      </a>

      <a
        href="mailto:qbitresearch.team@gmail.com"
        style={{
          textDecoration: 'none',
          color: '#fff',
          fontFamily: 'Helvetica',
          fontWeight: '600',
          transition: 'all 0.3s ease',
        }}
        onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
        onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
      >
        Email
      </a>

      <a
        href="https://github.com/qbit-research" // Replace with your GitHub link
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: '#fff',
          fontFamily: 'Helvetica',
          fontWeight: '600',
          transition: 'all 0.3s ease',
        }}
        onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
        onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
      >
        Github
      </a>
    </div>
  );
};

const ResearchInterests = () => {
  const interests = [
    // 'Geometric deep learning',
    'Neural ODEs and control systems',
    'Transformers and language models',
  ];

  return (
    <div style={{ marginTop: '30px', width: '100%', textAlign: 'center' }}>
      <h3 style={{ marginBottom: '10px', color: '#fff', fontFamily: 'Helvetica', fontWeight: '600'}}>
        Research Interests
      </h3>
      <ul
        style={{
          listStyleType: 'none',
          padding: 0,
          fontSize: '18px', // Same size as body text
          color: '#fff',
          fontFamily: 'Times New Roman',
          // textTransform: 'uppercase',
          lineHeight: '1.5',
        }}
      >
        {interests.map((interest, index) => (
          <li key={index} style={{ marginBottom: '10px' }}>
            {interest}
          </li>
        ))}
      </ul>
    </div>
  );
};

const AsciiArtTitle = () => {
  return (
    <pre style={{ fontSize: '12px', color: '#fff', marginBottom: '20px' }}> {/* Adjusted font size */}
  {String.raw`
                  ..       .         s                                     .x+=:.                                                             
            . uW8"        @88>      :8                                    z\`    ^%                                                  .uef^"    
            \`t888         %8P      .88             .u    .                   .   <k                           .u    .             :d88E       
    .u@u     8888   .      .      :888ooo        .d88B :@8c       .u       .@8Ned8"      .u          u      .d88B :@8c        .   \`888E       
 .zWF8888bx  9888.z88N   .@88u  -*8888888       ="8888f8888r   ud8888.   .@^%8888"    ud8888.     us888u.  ="8888f8888r  .udR88N   888E .z8k  
.888  9888   9888  888E ''888E\`   8888            4888>'88"  :888'8888. x88:  \`)8b. :888'8888. .@88 "8888"   4888>'88"  <888'888k  888E~?888L 
I888  9888   9888  888E   888E    8888            4888> '    d888 '88%" 8888N=*8888 d888 '88%" 9888  9888    4888> '    9888 'Y"   888E  888E 
I888  9888   9888  888E   888E    8888            4888>      8888.+"     %8"    R88 8888.+"    9888  9888    4888>      9888       888E  888E 
I888  9888   9888  888E   888E   .8888Lu=        .d888L .+   8888L        @8Wou 9%  8888L      9888  9888   .d888L .+   9888       888E  888E 
\`888Nx?888  .8888  888"   888&   ^%888*          ^"8888*"    '8888c. .+ .888888P\`   '8888c. .+ 9888  9888   ^"8888*"    ?8888u../  888E  888E 
 "88" '888   \`%888*%"     R888"    'Y"              "Y"       "88888%   \`   ^"F      "88888%   "888*""888"     "Y"       "8888P'  m888N= 888> 
       88E      "\`         ""                                   "YP'                   "YP'     ^Y"   ^Y'                  "P'     \`Y"   888  
       98>                                                                                                                              J88"  
       '8                                                                                                                               @%    
        \`                                                                                                                             :"      
  `}
    </pre>
  );
};

const LandingPage = () => {
  return (
    <div
      style={{
        color: '#fff',
        backgroundColor: '#000',
        minHeight: '100vh',
        padding: '40px',  // Increased padding for overall layout
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Times New Roman',
      }}
    >
      <div className="page" style={{ textAlign: 'center', padding: '20px' }}>

        <AsciiArtTitle />
        <SocialLinks />  {/* Added SocialLinks component */}



        <p style={{ fontSize: '18px', lineHeight: '1.5', margin: '0 auto 30px auto', maxWidth: '600px' }}> {/* Centered text */}
          Qbit Research is an open-source and non-profit lab with a focus on machine learning and applications in data processing. Our first project will be released in Q4 of 2024.
        </p>

        <ResearchInterests />

        <MailingListForm />

        <footer
          style={{
            marginTop: '30px',
            textAlign: 'center',
            fontSize: '14px',  // Smaller footer text size
            fontFamily: 'Helvetica',
            fontWeight: '600',
            // textTransform: 'uppercase',
          }}
        >
          <p>© 2024 Qbit Research</p>
          <p>Contact: qbitresearch.team@gmail.com</p>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;


// import React, { useState, useEffect, useRef } from 'react';
// import * as THREE from 'three';

// const DecodeText = ({ text }) => {
//   const [decodedText, setDecodedText] = useState('');
//   const symbols = '01ΦΨλπΣΩ∫∂∇∆';

//   useEffect(() => {
//     let interval;
//     let decodedChars = new Array(text.length).fill('');
//     let iterationCount = 0;

//     const decode = () => {
//       let newText = '';
//       let fullyDecoded = true;

//       for (let i = 0; i < text.length; i++) {
//         if (decodedChars[i] === text[i]) {
//           newText += text[i];
//         } else {
//           fullyDecoded = false;
//           if (Math.random() < 0.1 || iterationCount > 30) {
//             decodedChars[i] = text[i];
//             newText += text[i];
//           } else {
//             newText += symbols[Math.floor(Math.random() * symbols.length)];
//           }
//         }
//       }

//       setDecodedText(newText);
//       iterationCount++;

//       if (fullyDecoded) {
//         clearInterval(interval);
//       }
//     };

//     interval = setInterval(decode, 50);

//     return () => clearInterval(interval);
//   }, [text]);

//   return <span>{decodedText}</span>;
// };

// const TopologyAnimation = () => {
//   const mountRef = useRef(null);

//   useEffect(() => {
//     const width = mountRef.current.clientWidth;
//     const height = mountRef.current.clientHeight;

//     // Scene setup
//     const scene = new THREE.Scene();
//     scene.background = new THREE.Color(0x000000); // Black background

//     // Camera setup
//     const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
//     camera.position.set(0, 0, 105);
//     camera.lookAt(0, 0, 0);

//     // Renderer with anti-aliasing
//     const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
//     renderer.setSize(width, height);
//     renderer.setPixelRatio(window.devicePixelRatio); // Improve rendering on high-DPI screens
//     mountRef.current.appendChild(renderer.domElement);

//     // Create Lorenz Attractor animation
//     const maxPoints = 5000; // Maximum number of points
//     const positions = new Float32Array(maxPoints * 3); // 3 vertices per point

//     // Create the geometry and set draw range
//     const geometry = new THREE.BufferGeometry();
//     geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
//     geometry.setDrawRange(0, 0);

//     // Material
//     const material = new THREE.LineBasicMaterial({ color: 0xffffff });

//     // Line object
//     const line = new THREE.Line(geometry, material);
//     scene.add(line);

//     // Lorenz Attractor initial conditions
//     let x = 0.1;
//     let y = 0;
//     let z = 0;
//     const dt = 0.01;
//     const sigma = 10;
//     const rho = 28;
//     const beta = 8 / 3;

//     let currentIndex = 0;
//     let totalPoints = 0;

//     // Flag to stop animation after reaching maxPoints
//     let animationStopped = false;

//     const animate = () => {
//       if (!animationStopped) {
//         requestAnimationFrame(animate);

//         // Update Lorenz Attractor points
//         for (let i = 0; i < 5; i++) {
//           const dx = sigma * (y - x) * dt;
//           const dy = (x * (rho - z) - y) * dt;
//           const dz = (x * y - beta * z) * dt;

//           x += dx;
//           y += dy;
//           z += dz;

//           positions[currentIndex++] = x;
//           positions[currentIndex++] = y;
//           positions[currentIndex++] = z;

//           totalPoints++;
//           if (totalPoints >= maxPoints) {
//             // Stop animation when max points are reached
//             animationStopped = true;
//             break;
//           }
//         }

//         // Update draw range and geometry attributes
//         geometry.setDrawRange(0, totalPoints);
//         geometry.attributes.position.needsUpdate = true;

//         renderer.render(scene, camera);
//       }
//     };
//     animate();

//     // Clean up
//     return () => {
//       mountRef.current?.removeChild(renderer.domElement);
//     };
//   }, []);

//   return (
//     <div
//       ref={mountRef}
//       style={{ width: '100%', height: '600px', margin: 'auto' }}
//     />
//   );
// };

// const ResearchInterests = () => {
//   const interests = [
//     'geometric deep learning',
//     'physics-informed neural networks',
//     'action-perception systems for agentic movement',
//   ];

//   return (
//     <div
//       style={{
//         marginTop: '40px',
//         width: '100%',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         textAlign: 'center',
//       }}
//     >
//       <h3 style={{ marginBottom: '20px', fontSize: '2em' }}>
//         <DecodeText text="Research Interests" />
//       </h3>
//       <ul
//         style={{
//           listStyleType: 'none',
//           padding: 0,
//           display: 'inline-block',
//           textAlign: 'left',
//         }}
//       >
//         {interests.map((interest, index) => (
//           <li
//             key={index}
//             style={{
//               marginBottom: '15px',
//               display: 'flex',
//               alignItems: 'center',
//             }}
//           >
//             <span style={{ marginRight: '10px', color: '#00ff00' }}>▹</span>
//             <DecodeText text={interest} />
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// const MailingListForm = () => {
//   const [email, setEmail] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Subscribed:', email);
//     setEmail('');
//   };

//   return (
//     <div style={{ marginTop: '40px', width: '100%' }}>
//       <h3 style={{ marginBottom: '10px', fontSize: '1.2em' }}>
//         <DecodeText text="Mailing List" />
//       </h3>
//       <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Enter your email"
//           required
//           style={{
//             flex: 1,
//             padding: '10px',
//             fontSize: '16px',
//             backgroundColor: '#000',
//             color: '#fff',
//             border: '1px solid #fff',
//             borderRadius: '4px 0 0 4px',
//           }}
//         />
//         <button
//           type="submit"
//           style={{
//             padding: '10px 20px',
//             fontSize: '16px',
//             backgroundColor: '#fff',
//             color: '#000',
//             border: '1px solid #fff',
//             borderRadius: '0 4px 4px 0',
//             cursor: 'pointer',
//           }}
//         >
//           Subscribe
//         </button>
//       </form>
//     </div>
//   );
// };
// const NavLink = ({ text, isActive, onClick }) => {
//   return (
//     <span
//       onClick={onClick}
//       style={{
//         cursor: 'pointer',
//         marginRight: '20px',
//         position: 'relative',
//         color: isActive ? '#00ff00' : '#ffffff',
//       }}
//     >
//       <DecodeText text={text} />
//       <span
//         style={{
//           position: 'absolute',
//           bottom: '-2px',
//           left: '0',
//           width: '0',
//           height: '1px',
//           backgroundColor: '#00ff00',
//           transition: 'width 0.3s ease',
//         }}
//         className="underline"
//       />
//       <style jsx>{`
//         span:hover .underline {
//           width: 100%;
//         }
//       `}</style>
//     </span>
//   );
// };

// const Navigation = ({ currentPage, setCurrentPage }) => {
//   return (
//     <nav style={{ marginBottom: '20px', textAlign: 'center' }}>
//       <NavLink 
//         text="Home" 
//         isActive={currentPage === 'home'} 
//         onClick={() => setCurrentPage('home')} 
//       />
//       <NavLink 
//         text="Releases" 
//         isActive={currentPage === 'releases'} 
//         onClick={() => setCurrentPage('releases')} 
//       />
//     </nav>
//   );
// };

// const ReleasesPage = ({ setCurrentPage }) => {
//   return (
//     <div style={{
//       fontFamily: "'VT323', monospace",
//       color: '#ffffff',
//       backgroundColor: '#000000',
//       minHeight: '100vh',
//       padding: '40px',
//       fontSize: '20px',
//       textTransform: 'lowercase',
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//     }}>
//       <div style={{ maxWidth: '800px', width: '100%' }}>
//         <Navigation currentPage="releases" setCurrentPage={setCurrentPage} />
//         <header style={{ 
//           marginBottom: '40px', 
//           textAlign: 'center'
//         }}>
//           <h1 style={{ fontSize: '2.5em', margin: '0' }}>
//             <DecodeText text="Releases" />
//           </h1>
//         </header>
//         <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px' }}>
//           <thead>
//             <tr>
//               <th style={{ border: '1px solid #fff', padding: '10px' }}>Name</th>
//               <th style={{ border: '1px solid #fff', padding: '10px' }}>Link</th>
//               <th style={{ border: '1px solid #fff', padding: '10px' }}>Description</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td colSpan="3" style={{ border: '1px solid #fff', padding: '10px', textAlign: 'center' }}>
//                 <DecodeText text="Coming Q4 2024" />
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// const LandingPage = () => {
//   const [currentPage, setCurrentPage] = useState('home');

//   if (currentPage === 'releases') {
//     return <ReleasesPage setCurrentPage={setCurrentPage} />;
//   }

//   return (
//     <div
//       style={{
//         fontFamily: "'VT323', monospace",
//         color: '#ffffff',
//         backgroundColor: '#000000',
//         minHeight: '100vh',
//         padding: '40px',
//         fontSize: '20px',
//         textTransform: 'lowercase',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//       }}
//     >
//       <div style={{ maxWidth: '800px', width: '100%' }}>
//         <Navigation currentPage={currentPage} setCurrentPage={setCurrentPage} />
//         <header style={{ 
//           marginBottom: '40px', 
//           textAlign: 'center'
//         }}>
//           <h1
//             style={{
//               fontFamily: "'VT323', monospace",
//               fontSize: '3.5em',
//               letterSpacing: '0.1em',
//               textTransform: 'uppercase',
//               lineHeight: '1.4',
//               textRendering: 'optimizeLegibility',
//               WebkitFontSmoothing: 'antialiased',
//               margin: '0',
//             }}
//           >
//             <DecodeText text="QBIT RESEARCH" />
//           </h1>
//         </header>
//         <main>
//           <section style={{ marginBottom: '40px', lineHeight: '1.6' }}>
//             <p style={{ marginBottom: '15px' }}>
//               <DecodeText text="qbit research is an open-source and non-profit lab with a focus on machine learning. Our first project will be released in quarter four of 2024." />
//             </p>
//           </section>
//         </main>
//         <p style={{ marginBottom: '30px' }}>
//           <DecodeText text="Please contact us with any and all inquiries. We are happy to welcome collaborators and new team members. You can reach out to us by messaging qbitresearch.team@gmail.com." />
//         </p>

//         <div style={{ margin: '60px 0' }}>
//           <TopologyAnimation />
//         </div>

//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             width: '100%',
//           }}
//         >
//           <ResearchInterests />
//         </div>

//         <MailingListForm />

//         <footer
//           style={{
//             marginTop: '40px',
//             textAlign: 'center',
//             opacity: '0.7',
//           }}
//         >
//           <p>
//             <DecodeText text="© 2024 qbit research." />
//           </p>
//         </footer>
//       </div>
//     </div>
//   );
// };

// export default LandingPage;

